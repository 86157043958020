export const AuthRoutes = [
  {
    path: "/auth",
    component: () => import("./AuthModule.vue"),
    children: [
      {
        path: "sign-up",
        name: "SignUp",
        component: () => import("./views/SignUp.vue"),
        meta: {
          noAuthenticated: true,
        },
      },
      {
        path: "sign-in",
        name: "SignIn",
        component: () => import("./views/SignIn.vue"),
        meta: {
          noAuthenticated: true,
        },
      },
      {
        path: "user-profile",
        name: "AuthUserProfile",
        component: () => import("./views/UserProfile.vue"),
        meta: {
          requireLogin: true,
        },
      },
    ],
  },
];
