export const TicketRoutes = [
  {
    path: "/ticket",
    component: () => import("./TicketModule.vue"),
    children: [
      {
        path: "",
        name: "TicketHome",
        component: () => import("./views/TicketHome.vue"),
        meta: {
          requireLogin: true,
        },
      },
      {
        path: "/pay",
        name: "TicketPay",
        component: () => import("./views/Payment.vue"),
        meta: {
          requireLogin: true,
        },
      },
      {
        path: "/detail/:slug",
        name: "TicketDetail",
        component: () => import("./views/TicketDetail.vue"),
        meta: {
          requireLogin: true,
        },
      },

      // Ticket buyed by user
      {
        path: "your-ticket",
        name: "YourTicket",
        component: () => import("./views/YourTicket.vue"),
        meta: {
          requireLogin: true,
        },
      },
      {
        path: "your-ticket/invoice",
        name: "TicketInvoice",
        component: () => import("./views/TicketInvoice.vue"),
        meta: {
          requireLogin: true,
        },
      },
    ],
  },
];
