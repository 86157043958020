<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <div id="page">
    <div class="header header-fixed header-logo-center header-auto-show">
      <a href="index.html" class="header-title">Subscriptions</a>
      <a href="#" data-back-button class="header-icon header-icon-1"
        ><i class="fas fa-chevron-left"></i
      ></a>
      <a href="#" data-menu="menu-main" class="header-icon header-icon-4"
        ><i class="fas fa-bars"></i
      ></a>
      <a
        href="#"
        data-toggle-theme
        class="header-icon header-icon-3 show-on-theme-dark"
        ><i class="fas fa-sun"></i
      ></a>
      <a
        href="#"
        data-toggle-theme
        class="header-icon header-icon-3 show-on-theme-light"
        ><i class="fas fa-moon"></i
      ></a>
    </div>

    <div class="page-content pb-0">
      <div class="card rounded-0" data-card-height="cover-full">
        <div class="card-center text-center ps-3">
          <h1 class="font-40 font-800 mb-n1">
            Ticket<span
              class="gradient-highlight p-2 mx-1 color-white scale-box d-inline-block rounded-s border-0"
            ></span>
            <h1 class="text-center mt-4">
              <img src="/img/splash-1.svg" class="mx-auto" width="240" />
            </h1>
          </h1>

          <router-link
            :to="{ name: 'SignIn' }"
            href="#"
            data-back-button
            class="btn btn-center-l gradient-highlight rounded-sm btn-l font-13 font-600 mt-5 scale-box"
            >Get Started</router-link
          >
          <p class="font-10 line-height-s mt-4">
            Lorem ipsum dolor sit amet consectetur adipisicing elit.<br />
            alias aliquam debitis, necessitatibus excepturi illum nobis dolorem
            beatae tenetur laudantium!
          </p>
        </div>
      </div>
    </div>
    <!-- Page content ends here-->
  </div>
</template>

<script>
import { init_template, menuClose, menuOpen } from "@/appkit.js";

export default {
  components: {},
  data() {
    return {
      isLoading: true,
    };
  },
  mounted() {
    init_template();
    setTimeout(() => {
      this.isLoading = false;
    }, 150);
  },
  methods: {
    menuClose,
    menuOpen,
  },
};
</script>
