import { createStore } from "vuex";

import { AuthStore } from "@/modules/authentication/store";
import { TicketStore } from "@/modules/ticket/store";
export default createStore({
  state: {},
  mutations: {},
  actions: {
    initializeStore({ commit }) {
      commit("auth/initializeAuth");
    },
  },
  modules: {
    auth: AuthStore,
    ticket: TicketStore,
  },
});
