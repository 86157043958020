const ticketService = require("./services.js");

export const TicketStore = {
  namespaced: true,
  state: {
    ticketData: null,
    ticketDetail: null,
    userTicketData: null,
    userTicketDataExpired: null,
  },
  mutations: {
    setTicketList(state, { ticketData }) {
      state.ticketData = ticketData;
    },
    setEventDetail(state, { ticketData }) {
      state.ticketDetail = ticketData;
    },

    // Your Ticket
    setUserTicketList(state, { ticketData }) {
      state.userTicketData = ticketData;
    },
    setUserTicketExpiredList(state, { ticketData }) {
      state.userTicketDataExpired = ticketData;
    },
  },
  getters: {
    getTicketList(state) {
      return state.ticketData;
    },
    getEventDetail(state) {
      return state.ticketDetail;
    },

    //Your Ticket
    getUserTicket(state) {
      return state.userTicketData;
    },

    getUserTicketExpired(state){
      return state.userTicketDataExpired;
    }
  },
  actions: {
    tickets({ commit }) {
      return new Promise((resolve, reject) => {
        ticketService
          .getTickets()
          .then((success) => {
            commit("setTicketList", { ticketData: success });
            resolve(success);
          })
          .catch((error) => {
            commit("setTicketList", { ticketData: [] });
            console.log("ticket store : ", error);
            reject(error);
          });
      });
    },
    ticketDetail({ commit }, { slug }) {
      return new Promise((resolve, reject) => {
        ticketService
          .getEvent(slug)
          .then((success) => {
            commit("setEventDetail", { ticketData: success });
            resolve(success);
          })
          .catch((error) => {
            commit("setEventDetail", { ticketData: "" });
            console.log("ticket store : ", error);
            reject(error);
          });
      });
    },
    ticketAdd({ commit }, { formData }) {
      return new Promise((resolve, reject) => {
        ticketService
          .createEvent(formData)
          .then((success) => {
            console.log(success);
            resolve(success);
          })
          .catch((error) => {
            console.log("ticket store : ", error);
            reject(error);
          });
      });
    },

    // your ticket
    userTicket({ commit }) {
      return new Promise(async(resolve, reject) => {
        ticketService.getUserTicketExpired()
          .then((success) => {
            commit("setUserTicketExpiredList", { ticketData: success });
          })
          .catch((error) => {
            commit("setUserTicketList", { ticketData: success });
            console.log("ticket store : ", error);
            reject(error);
          });

        ticketService
          .getUserTickets()
          .then((success) => {
            commit("setUserTicketList", { ticketData: success });
          })
          .catch((error) => {
            commit("setUserTicketList", { ticketData: success });
            console.log("ticket store : ", error);
            reject(error);
          });
            resolve('success')
      });
    },
  },
};
