import api from "@/services/api";

const errorHandler = (error) => {
  let _return = {
    status: "",
    message: "",
  };
  if (error.response) {
    _return.message = error.response.data.detail;
    _return.status = error.response.status;
    console.log("auth services: ", JSON.stringify(error.response.data));
  } else if (error.message) {
    _return.message = "Something went wrong. please try again";
    console.log("auth services: ", JSON.stringify(error.message));
  }
  return _return;
};

export const getAccount = (formData) => {
  return new Promise((resolve, reject) => {
    api
      .post("/auth/sign-in/", formData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        const _return = errorHandler(error);
        reject(_return);
      });
  });
};
export const registerAccount = (formData) => {
  return new Promise((resolve, reject) => {
    api
      .post("/auth/register/", formData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        const _return = errorHandler(error);
        reject(_return);
      });
  });
};

export const signInByOauth = (formData) => {
  return new Promise((resolve, reject) => {
    api
      .post("/auth/register/social-oauth/google/", formData)
      .then((response) => {
        console.log("response : ", response);
        resolve(response.data);
      })
      .catch((error) => {
        const _return = errorHandler(error);
        reject(_return);
      });
  });
};
