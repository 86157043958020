import api from "@/services/api";

const errorHandler = (error) => {
  let _return = {
    status: "",
    message: "",
  };
  if (error.response) {
    _return.message = error.response.data.detail;
    _return.status = error.response.status;
    console.log("event services: ", JSON.stringify(error.response.data));
  } else if (error.message) {
    _return.message = "Something went wrong. please try again";
    console.log("event services: ", JSON.stringify(error.message));
  }
  return _return;
};

export const getTickets = () => {
  return new Promise((resolve, reject) => {
    let url = "/ticket/all";
    api
      .get(url)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        const _return = errorHandler(error);
        reject(_return);
      });
  });
};

export const getTicket = (id) => {
  return new Promise((resolve, reject) => {
    let url = "/ticket/" + id;
    api
      .get(url)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        const _return = errorHandler(error);
        reject(_return);
      });
  });
};
export const createEvent = (formData) => {
  return new Promise((resolve, reject) => {
    // Event Detail
    api
      .post("/event/", formData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        const _return = errorHandler(error);
        reject(_return);
      });
  });
};

export const getTransactionToken = (formData) => {
  return new Promise((resolve, reject) => {
    // Event Detail
    api
      .post("/ticket/transaction-token", formData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        const _return = errorHandler(error);
        reject(_return);
      });
  });
};
export const doBuyTicket = (formData) => {
  return new Promise((resolve, reject) => {
    // Event Detail
    api
      .post("/ticket/buy/save", formData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        const _return = errorHandler(error);
        reject(_return);
      });
  });
};

// Your Ticket
export const getUserTicketExpired = () => {
  return new Promise((resolve, reject) => {
    let url = "/ticket/user/expired";
    api
      .get(url)
      .then((response) => {
        resolve(response.data.data);
        console.log('data  ticket service expired: ', response.data.data)
      })
      .catch((error) => {
        const _return = errorHandler(error);
        reject(_return);
      });
  });
};
export const getUserTickets = () => {
  return new Promise((resolve, reject) => {
    let url = "/ticket/user/valid";
    api
      .get(url)
      .then((response) => {
        resolve(response.data.data);
        console.log('data  ticket service: ', response.data.data)
      })
      .catch((error) => {
        const _return = errorHandler(error);
        reject(_return);
      });
  });
};
