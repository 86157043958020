<template>
  <router-view />
</template>

<script>
export default {
  components: {},
  beforeCreate() {
    this.$store.dispatch("initializeStore");
  },
};
</script>
<style lang="scss">
.btn-custom-full {
  width: 100%;
}
</style>
