import axiosInstance from "./api";
const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = store.getters["auth/getToken"];
      if (token) {
        config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (
        originalConfig &&
        originalConfig.url !== "/auth/sign-in" &&
        err.response
      ) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            const rs = await axiosInstance.post("/auth/token/refresh/", {
              refresh: store.getters["auth/getRefreshToken"],
            });
            const { access } = rs.data;
            store.dispatch("auth/refreshToken", { accessToken: access });
            return axiosInstance(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }
      return Promise.reject(err);
    }
  );
};
export default setup;
