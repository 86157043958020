import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import { AuthRoutes } from "@/modules/authentication/router";
import { TicketRoutes } from "@/modules/ticket/router";
import NotFound from "@/views/NotFound.vue";
import Splash from "@/views/Splash.vue";
const routes = [
  {
    path: "/",
    name: "Splash",
    component: Splash,
    meta: {
      noAuthenticated: true,
    },
  },

  {
    path: "/explorer",
    name: "Explorer",
    component: () => import("@/views/Explorer.vue"),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/content-video",
    name: "ContentVideo",
    component: () => import("@/views/ContentVideo.vue"),
    meta: {
      requireLogin: true,
    },
  },
  ...AuthRoutes,
  ...TicketRoutes,

  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requireLogin) &&
    !store.state.auth.isAuthenticated
  ) {
    next({ name: "SignIn", query: { to: to.path } });
  } else if (
    to.matched.some((record) => record.meta.noAuthenticated) &&
    store.state.auth.isAuthenticated
  ) {
    next({ name: "TicketHome" });
  } else {
    console.log("path last : ", from.path);
    next();
  }
});
export default router;
