import { jwtDecrypt } from "./helpers/jwt";

const authService = require("./services.js");

export const AuthStore = {
  namespaced: true,
  state: {
    isAuthenticated: false,
    authData: null,
  },
  mutations: {
    initializeAuth(state) {
      if (localStorage.getItem(btoa("auth"))) {
        state.isAuthenticated = true;
        state.authData = JSON.parse(localStorage.getItem(btoa("auth")));
      } else {
        state.isAuthenticated = false;
        state.authData = {};
      }
    },
    setAuthData(state, { authData }) {
      state.authData = authData;
      state.isAuthenticated = true;
      localStorage.setItem(btoa("auth"), JSON.stringify(authData));
    },
    setToken(state, { accessToken }) {
      state.authData.tokens.access = accessToken;
      state.isAuthenticated = true;
      localStorage.setItem(btoa("auth"), JSON.stringify(state.authData));
    },
    clearData(state) {
      state.authData = null;
      localStorage.removeItem(btoa("auth"));
      state.isAuthenticated = false;
    },
  },
  getters: {
    getToken(state) {
      let token = state.isAuthenticated ? state.authData.tokens.access : false;
      return token;
    },
    getRefreshToken(state) {
      return state.isAuthenticated ? state.authData.tokens.refresh : false;
    },
    authData(state) {
      return state.authData;
    },
    isVerified(state) {
      return state.authData.is_verified;
    },
  },
  actions: {
    signOut({ commit }) {
      return new Promise((resolve, reject) => {
        commit("clearData");
        resolve({ success: true });
      });
    },
    signIn({ commit }, { formData }) {
      return new Promise((resolve, reject) => {
        authService
          .getAccount(formData)
          .then((success) => {
            commit("setAuthData", { authData: success });
            resolve(success);
          })
          .catch((error) => {
            console.log("auth store : ", error);
            reject(error);
          });
      });
    },
    signUp({ commit }, { formData }) {
      return new Promise((resolve, reject) => {
        authService
          .registerAccount(formData)
          .then((success) => {
            commit("setAuthData", { authData: success });
            resolve(success);
          })
          .catch((error) => {
            console.log("auth store : ", error);
            reject(error);
          });
      });
    },
    signInOauth({ commit }, { formData }) {
      return new Promise((resolve, reject) => {
        authService
          .signInByOauth(formData)
          .then((success) => {
            commit("setAuthData", { authData: success });
            resolve("success");
          })
          .catch((error) => {
            console.log("auth store : ", error);
            reject(error);
          });
      });
    },
    refreshToken({ commit }, { accessToken }) {
      commit("setToken", { accessToken });
    },
  },
};
