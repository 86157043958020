import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import GAuth from "vue3-google-oauth2";
import VueSplide from "@splidejs/vue-splide";

// AppKit
import "bootstrap";
// axios Interceptors
import setupInterceptors from "./services/setupInterceptors";
setupInterceptors(store);

const app = createApp(App);
const gAuthOptions = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: "email",
  fetch_basic_profile: false,
};

app.use(store).use(router);
app.use(GAuth, gAuthOptions);
app.use(VueSplide);
app.mount("#app");
